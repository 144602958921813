import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static targets = ['divSelector', 'btnShowSelector', 'select'];
  static values = {
    url: String
  }
  

  showSelect(e) {
      e.preventDefault();
      this.btnShowSelectorTarget.classList.add('d-none');
      this.divSelectorTarget.classList.remove('d-none');
      
  }
  
  cancel() {
      this.divSelectorTarget.classList.add('d-none');
      this.btnShowSelectorTarget.classList.remove('d-none');
  }
  
  showBillingPeriod() {
      Turbo.visit(this.urlValue + '?idPeriod=' + this.selectTarget.value);
  }  

}