import * as Turbo from '@hotwired/turbo'; // eslint-disable-line no-unused-vars
import { Application } from '@hotwired/stimulus';
import * as Tabler from '@tabler/core/dist/js/tabler.min.js';
import BillingPeriodSelectorController from './controllers/billing_period_selector_controller.js';
import TrainingSelectorController from './controllers/training_selector_controller.js';
import InvoiceCompanySelectorController from './controllers/invoice_company_selector_controller.js';
import ChartJsController from '@symfony/ux-chartjs';
import ChartController from './controllers/chart_controller.js';

import jQuery from "jquery";
window.$ = jQuery;

import {popper} from "@popperjs/core";
window.popper = popper;

import './p6tools.js';

const application = Application.start();
application.register('billing-period-selector', BillingPeriodSelectorController);
application.register('training-selector', TrainingSelectorController);
application.register('invoice-company-selector', InvoiceCompanySelectorController);
application.register('symfony--ux-chartjs--chart', ChartJsController); 
application.register('chart', ChartController); 
