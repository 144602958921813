import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

    static targets = ['business', 'delegationPercentage'];

    _connected() {
        const event = new Event("change");
        this.businessTarget.dispatchEvent(event);
    }

    setDelegationPercentage(e) {
        let percentage = e.target.options[e.target.selectedIndex].dataset.delegationPercentage;
        this.delegationPercentageTarget.value = percentage;
    }

}