import { Controller } from '@hotwired/stimulus';
import {Chart} from 'chart.js';

export default class extends Controller {

  connect() {

//    Chart.register(ChartDataLabels);
    this.element.addEventListener('chartjs:pre-connect', this._onPreConnect);
    this.element.addEventListener('chartjs:connect', this._onConnect);
  }
  
_onConnect(event) {
    console.log(event);
    const labely = event.target.dataset.labely;
    var showValues = false;
    const hiddenImg = event.target.nextElementSibling
    event.detail.chart.options = {
      responsive: true,    
      plugins: {
        legend: {
          display: false,
        },        
        datalabels: {
          color: '#000000',
          display: false,
        }
      },
      scales: {
        y: {
          display: true,
          title: {
            display: true,
            text: labely
          }
        },
      }
    };

    const showValuesBtn = document.getElementById('show-chart-values');
    if (showValuesBtn){
      showValuesBtn.dataset.labely = labely;
    }
    
    
    if (showValuesBtn) {
      
      showValuesBtn.addEventListener("click", function (e) {  
        e.preventDefault();
        const labely = e.target.dataset.labely;          
        event.detail.chart.options = {
          plugins: {
            legend: {
              'display': false,
            },
            datalabels: {
              color: '#000000',
              display: showValues ? false : true,
              labels: {
                 title: {
                   font: {
                     weight: '500',
                     size: '12px'
                   }
                 },   
               },
              formatter: function (value, context) {
                return Number.isInteger(Number.parseFloat(value)) ? Number(value).toLocaleString() : Number(value).toFixed(0);
              }
            },
          },
          scales: {
            y: {
              display: true,
              title: {
                display: true,
                text: labely
              }
            },
          }          
        };
        event.detail.chart.update();
        e.target.innerHTML = showValues ? e.target.getAttribute('data-show-text') : e.target.getAttribute('data-hide-text');
        showValues = showValues ? false : true;
      });
    }


    // event.detail.chart.update();
    // event.detail.chart.options.plugins.legend = false;
    event.detail.chart.update();
  }
}